* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button {
    outline: none !important;
}

.ff-mst{
    font-family: 'Montserrat', sans-serif;
}

.ff-rbt{
    font-family: 'Roboto', sans-serif;
}

.pf{
    font-family: 'Vast Shadow';
}

.fxs{
    font-size: 0.6rem;
}

.fsm{
    font-size: 0.8rem;
}

.fmd{
    font-size: 1.2rem;
}

.flg{
    font-size: 1.5rem;
}

.fxl{
    font-size: 2rem;
}

.full-screen{
    width:100%;
    min-height: 100vh;
}

.bg-black{
    background-color:black;
}

.vl{
 border-left:2px dotted yellow;
 height:10vh
}

.sidebar{
 display: flex;
 flex-direction: column;
 align-items: center;
 position:fixed;
 top:0;
 left:0;
 height:100vh;
 z-index: 10;
}


.pc{
    display: block !important;
}

.mbl{
    display: none !important;
}

.bg-gradient{
    background-image: linear-gradient(to left, #161616 20%, black 100%);
}

.project-img{
    width:60vw
}

.rounded-my{
    border-radius: 1.5rem;
}

.p-404{
    position:'fixed';
    top:0;
    left:0;
    width:100%;
    height:100vh;
    z-index:1000
}

@media only screen and (max-width:768px){
    
    .pc{
        display: none !important;
    }
    .mbl{
        display: block !important;
    }

    .project-img-mbl{
        height:'60vh'
    }

    .overlay{
        width:100%;
        position:fixed;
        top:0;
        left:0;
        height:100vh;
        background-color: rgba(0, 0, 0, 0.61);
        z-index:5
    }
}
